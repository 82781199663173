:root {
  --imgHeight: 45em;
  --headerTop: 35em;
}
.bgImg {
  display: none;
}
.header-wrapper .item {
  color: #000 !important;
}
.header-wrapper .item:hover {
  border-color: #d67a50 !important;
}
.header-wrapper .icon {
  fill: #fff !important;
}
.header-wrapper .transparent.input input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.header-wrapper .transparent.input input::placeholder,
.header-wrapper .transparent.input input {
  color: rgba(255, 255, 255, 0.8);
}
.header-wrapper .transparent.input input:focus::-moz-placeholder {
  color: #ffffff;
}
.header-wrapper .transparent.input input:focus::placeholder,
.header-wrapper .transparent.input input:focus {
  color: #ffffff;
}
.header-wrapper .searchbox.field {
  border-left: 1px solid #fff !important;
}
.header-wrapper .ui.secondary.pointing.menu .active.item {
  border-color: rgba(255, 255, 255, 0.7);
}
.header-wrapper .ui.secondary.pointing.menu .active.item:hover {
  border-color: #fff;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: #333;
}
.hamburger.is-active .hamburger-inner:after {
  background-color: #000;
}
.tools-search-wrapper .tools {
  display: none !important;
}
@media only screen and (min-width: 2230px) {
  :root {
    --imgHeight: 61em;
    --headerTop: 51em;
  }
  .bgImg {
    height: var(--imgHeight);
  }
  .ui.segment.header-wrapper {
    top: var(--headerTop);
  }
}
@media only screen and (min-width: 600px) {
  .logo-nav-wrapper .logo a img.ui.image {
    height: 10em;
  }
}
